@use 'theme';

.wrapper {
  & h2, & [data-header="h2"] {
    &:not(:first-child) {
      margin-top: theme.spacing(6);
    }
  }

  & h3, & [data-header="h3"] {
    &:not(:first-child) {
      margin-top: theme.spacing(5);
    }
  }

  & h4, & [data-header="h4"] {
    &:not(:first-child) {
      margin-top: theme.spacing(4);
    }
  }

  & h5, & [data-header="h5"] {
    &:not(:first-child) {
      margin-top: theme.spacing(3);
    }
  }

  & h6, & [data-header="h6"] {
    &:not(:first-child) {
      margin-top: theme.spacing(2);
    }
  }
}

.divider {
  border-width: 0;
  background-color: theme.get('dividerColor');
  height: 1px;
  margin: theme.spacing(3, 0);
}

.paragraphWithCards {
  display: flex;
  flex-wrap: wrap;

  &.smallCards {
    gap: theme.spacing(2);
  }
}
