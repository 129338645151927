@use 'theme';
@use 'gradients';
@use 'borders';
@use 'breakpoints';

.wide {
  width: 100%;
}
.wrapper {
  position: relative;
  overflow: hidden;
  border-radius: borders.radius('lg');
  padding: theme.spacing(3);
  background: theme.get('backgroundColor');

  &.main {
    background: theme.get('mainGradient');
    color: theme.palette('white');
  }

  &.lightBlue {
    background: gradients.get('lightBlue');
    color: theme.palette('white');
  }

  &.image {
    display: flex;
    align-items: center;
    justify-content: center;
    color: theme.palette('white');
  }

  &.border {
    background: theme.palette('white');
    border: borders.get($color: theme.get('activeColor'));
  }

  @include breakpoints.up('small') {
    padding: theme.spacing(4);
  }

  @include breakpoints.up('largeMedium') {
    padding: theme.spacing(8);
  }
}