@use 'theme';
@use 'borders';
@use 'breakpoints';
@use 'shadows';
@use 'transitions';
@use 'fonts';
@use 'display';
@use 'gradients';

.wrapper {
  display: flex;
  gap: theme.spacing(3);
  flex-direction: column;
  padding-bottom: theme.spacing(6);

  &:not(:last-of-type) {
    border-bottom: borders.get();
  }

  @include breakpoints.up('small') {
    gap: theme.spacing(1);
    padding-bottom: theme.spacing(0);
    &:not(:last-of-type) {
      border-bottom: none;
    }
  }

  @include breakpoints.up('medium') {
    display: grid;
    &.big {
      grid-template-columns: repeat(2, 1fr);
      gap: theme.spacing(3, 8);
      grid-template-areas: "image header"
                           "image description";

      & .header {
        justify-content: flex-end;
      }
    }

    &.small {
      grid-template-columns: 1fr;
      grid-template-rows: min-content min-content max-content;
      grid-template-areas: "image" "header" "description";
    }

    &.horizontal:not(.big) {
      grid-template-columns: 192px 1fr;
      grid-template-rows: 48px 1fr;
      gap: theme.spacing(0.5, 3);
      grid-template-areas: "image header"
                           "image description";
    }
  
    &.vertical {
      gap: theme.spacing(1, 8);
    }
  }
}

.title {
  @include breakpoints.up('small') {
    &.bigTitle {
      font-size: fonts.size('great');
      line-height: 30px;
    }
    &.smallTitle {
      min-height: 40px;
      font-size: fonts.size('small');
      line-height: 20px;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: theme.spacing(0.25);
  grid-area: header;
}

.imageLink {
  position: relative;
  grid-area: image;

  &:hover .image {
    box-shadow: shadows.get('default');
  }
}

.image {
  border-radius: borders.radius('lg');
  box-shadow: shadows.get('productCard');
  transition: transitions.get('box-shadow');
  height: 194px;
  
  &:not(.cover) {
    padding: theme.spacing(2);
  }

  &.cover {
    padding: theme.spacing(0);
  }

  & > span {
    height: 100%;
  }

  & picture {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    & img, & svg {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: auto;
    }
  }
}

.imageText {
  @include display.hideOnMobile();
  position: absolute;
  bottom: 0;
  right: 0;
  background: gradients.get('background');
  padding: theme.spacing(1.5, 2);
  border-radius: borders.radius('xLg', 0, 'xLg', 0);
}

.small {
  .image {
    @include breakpoints.up('small') {
      margin-bottom: theme.spacing(2);
    }
      
    @include breakpoints.up('largeMedium') {
      height: 181px;
    }
  }

  &.horizontal {
    .smallTitle {
      min-height: auto;
    }

    .image {
      @include breakpoints.up('largeMedium') {
        height: 100%;
        max-width: 100%;
        margin-bottom: theme.spacing(0);
        aspect-ratio: 16 / 9;
      }
    }

    .bottomContent {
      margin-top: theme.spacing(2);
    }
  }
}

.big {
  .image {
    @include breakpoints.up('small') {
      border-radius: borders.radius('xLg');
      height: 375px;
    }
  }
}

.teaser {
  opacity: 0.8;
}

.bottomContent {
  margin-top: theme.spacing(3);
}

.description {
  grid-area: description;
  height: calc(var(--ellipsisLines) * 23px);

  & * {
    display: inline;
  }
}
