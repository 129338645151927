@use 'theme';
@use 'breakpoints';

@mixin img {
  display: flex;
  width: 100%;
  max-width: 100%;
  height: auto;

  @include breakpoints.up('small') {
    width: var(--width);
  }
}

.imageWrapper {
  display: block;
  margin: theme.spacing(6, 0);
}

.imageDescription {
  display: block;
  margin: theme.spacing(2, 0, 0);
  text-align: center;
}

a.link {
  color: theme.get('darkerActiveColor');
}

.image {
  & img, & svg {
    @include img;
  }
}

.productImage {
  & img, & svg {
    @include img;

    @include breakpoints.up('medium') {
      max-width: 557px;
    }
  
    @include breakpoints.up('largeMedium') {
      max-width: 658px;
    }

    @include breakpoints.up('xLarge') {
      max-width: 960px;
    }
  }
}

.variantCard {
  width: auto;
  margin: theme.spacing(0);
}

.singleVariantCard {
  margin: theme.spacing(0);
}
