@use 'theme';
@use 'breakpoints';
@use 'transitions';
@use 'fonts';
@use 'display';

$logoHeight: 124px;
$logoMobileHeight: 109px;

@mixin generateGridWrapper($mobileRows, $tabletRows, $dekstopRows) {
  --customContainerHeight: #{$logoMobileHeight * 3};

  @include breakpoints.up('small') {
    --customContainerHeight: #{$logoHeight * $tabletRows};
  }

  @include breakpoints.up('large') {
    --customContainerHeight: #{$logoHeight * $dekstopRows};
  }
}

.trustedUs {
  margin: theme.spacing(8, 2, 6);

  @include breakpoints.up('small') {
    margin: theme.spacing(15, 0);
  }
}

.headerWrapper {
  margin-bottom: theme.spacing(5);

  @include breakpoints.up('small') {
    margin-bottom: theme.spacing(13);
  }
}

.wrapper {
  --containerHeight: var(--customContainerHeight);
}

.wrapper12 {
  @include generateGridWrapper(6, 3, 2);
}

.wrapper18 {
  @include generateGridWrapper(9, 5, 3);
}

.logosContent {
  @include breakpoints.up('small') {
    --gradientHeight: 0;
    margin-bottom: theme.spacing(0);
  }
}

.logos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;

  @include breakpoints.up('small') {
    grid-template-columns: repeat(4, 1fr);
  }

  @include breakpoints.up('large') {
    grid-template-columns: repeat(6, 1fr);
  }
}

.logo {
  display: flex;
  justify-content: center;
  height: $logoMobileHeight;
  & img {
    width: 105px;
  }

  @include breakpoints.up('small') {
    height: $logoHeight;
    & img {
      width: 120px;
    }
  }
}

.skeleton {
  @include breakpoints.up('small') {
    & svg {
      width: 400px;
    }
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;

  @include breakpoints.up('small') {
    display: none;
  }
}

.button {
  @include display.hideOnDesktop;
}

.buttonContent {
  font-weight: fonts.weight('regular');
}