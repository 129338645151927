@use 'theme';
@use 'fonts';
@use 'transitions';
@use 'utils';

@include utils.defineProperty($name: 'containerHeight', $syntax: '<length> | <percentage> | auto');
@include utils.defineProperty($name: 'gradientHeight', $syntax: '<length> | <percentage> | auto');

.contentWrapper {
  margin-bottom: theme.spacing(6);
  position: relative;
}

.content {
  max-height: var(--containerHeight);
  overflow: hidden;
  mask-image: linear-gradient(0deg, transparent 0, theme.palette('black') var(--gradientHeight), theme.palette('black') 100%);
  transition: transitions.get(max-height);
}

.buttonContainer {
  width: fit-content;
  margin: theme.spacing(0, auto);
}

.buttonContent {
  font-weight: fonts.weight('bold');
}

.buttonIcon {
  height: 8px;
  width: auto;
  margin-left: theme.spacing(1.25);
  transition: transitions.get(transform);
  transform: rotate(90deg);

  .buttonRoot:hover & {
    fill: theme.palette('white');
  }

  &.expanded {
    transform: rotate(270deg);
  }
}
