@use 'theme';

.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: theme.spacing(1);
}

.tag {
  padding: theme.spacing(0, 1);
  min-height: 22px;
}

.time {
  padding-left: theme.spacing(1);
}
