@use 'theme';
@use 'fonts';
@use 'typography';
@use 'breakpoints';

.headerWrapper {
  text-align: center;
  max-width: 780px;
  margin: theme.spacing(0, 'auto');
}

.title {
  @include breakpoints.up('small') {
    @include typography.get('huge');
  }
}

.secondTitle {
  margin: theme.spacing(7, 'auto', 0);
}

.subtitle {
  width: auto;
  text-align: center;
  margin: theme.spacing(3, 'auto', 0);

  @include breakpoints.up('medium') {
    &.hasSecondTitle {
      margin: theme.spacing(2, 'auto', 0);
    }
  }
}
