@use 'theme';
@use 'borders';
@use 'breakpoints';

.wrapper {
  border-top: borders.get();
}

.header {
  display: flex;
  margin-top: theme.spacing(-0.125);
}

.title {
  border-top: borders.get($color: theme.get('darkDividerColor'));
  margin: theme.spacing(0);
  padding: theme.spacing(1, 0);
  text-transform: uppercase;
  letter-spacing: 1px;
}
