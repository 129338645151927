@use 'theme';
@use 'breakpoints';
@use 'borders';

.mainArticle {
  margin: theme.spacing(7, auto, 0, 0);
  &:not(:last-of-type) {
    border-bottom: borders.get();
  }

  @include breakpoints.up('small') {
    &:last-of-type, &:not(:last-of-type) {
      border-bottom: none;
    }
  }
}

.withoutSpacing {
  margin-bottom: theme.spacing(0);
}

.title {
  display: flex;
  align-items: center;

  &:not(.withMainArticle) {
    justify-content: space-between;
  }

  & .link {
    color: theme.get('darkActiveColor');
    margin-left: theme.spacing(2);
  }
}

.articlesWrapper {
  padding: theme.spacing(6, 0);
}

.articles {
  display: grid;
  grid-template-columns: 1fr;
  gap: theme.spacing(8, 2);
  max-width: 100%;

  @include breakpoints.up('small') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoints.up('largeMedium') {
    grid-template-columns: repeat(4, 1fr);
  }
}

.pagiantion {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: theme.spacing(6);
}
