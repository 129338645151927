@use 'theme';
@use 'fonts';
@use 'breakpoints';

.wrapper {
  align-items: center;
  color: rgba(theme.get('textColorRgb'), 0.8);
  display: flex;
  font-size: fonts.size('xSmall');
  justify-content: center;
  user-select: none;
}

.indicator {
  margin-left: theme.spacing(2);
  margin-right: theme.spacing(2);
  min-width: 35px;
  text-align: center;
}

.prevArrow {
  fill: rgba(theme.get('textColorRgb'), 0.6);
  height: 16px;
  transform: rotate(-180deg);
  width: 8px;

  &.hasPrev {
    fill: rgba(theme.get('textColorRgb'), 0.8);
  }
}

.nextArrow {
  fill: rgba(theme.get('textColorRgb'), 0.6);
  height: 16px;
  margin-left: theme.spacing(1);
  width: 8px;

  &.hasNext {
    fill: rgba(theme.get('textColorRgb'), 0.8);
  }
}

.prevLink {
  cursor: pointer;
  display: flex;
  padding: theme.spacing(0.5, 0);
  width: theme.spacing(2);
  opacity: 0.3;
  pointer-events: none;
}

.nextLink {
  color: rgba(theme.get('textColorRgb'), 0.8);
  opacity: 0.3;
  display: flex;
  padding: theme.spacing(0.5, 0);
  pointer-events: none;
}

.nextText {
  display: none;

  @include breakpoints.up('medium') {
    display: block;
  }
}

.isActive {
  color: rgba(theme.get('textColorRgb'), 0.8);
  opacity: 1;
  cursor: pointer;
  pointer-events: all;

  &:hover {
    color: theme.get('activeColor');
    text-decoration: underline;

    & .prevArrow, & .nextArrow {
      fill: theme.get('activeColor');
    }
  }
}
