@use 'theme';
@use 'borders';
@use 'breakpoints';

.wrapper {
  margin-bottom: theme.spacing(6);

  @include breakpoints.up('medium') {
    margin-bottom: theme.spacing(10);
  }
}

.header {
  margin-bottom: theme.spacing(2);
}
