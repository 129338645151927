@use 'theme';
@use 'gradients';

.wrapper {
  height: 300px;
  padding-right: theme.spacing(2);
  mask-image: gradients.get('fadeVertical');
  overflow-y: scroll;
}

.article {
  margin: theme.spacing(1.375, 0, 4);

  &:first-of-type {
    margin: theme.spacing(2.5, 0, 4);
  }

  & > p {
    white-space: pre-line;

    &:has(+ ul) {
      margin-bottom: theme.spacing(0);
    }
  }

  & > ul {
    margin: theme.spacing(0);
    padding-left: theme.spacing(3);

    ~ p {
      margin-top: theme.spacing(0);
    }
  }
}
